/* ----- Fonts ----- */

/* Style de base */
body {
    @apply font-body overflow-x-hidden;
}

h1 {
    @apply font-heading;
}
h2 {

}
h3 {

}

img {
    @apply inline-block;
}

main {
    & p {
        @apply mb-2;
    }
    a {
        @apply text-black hover:text-primary;
    }
}

/* Menu et sous-menu */
nav ul li {
    @apply relative px-2 py-1;
    & > ul {
        @apply w-max hidden;
    }
}

@screen lg {
    nav ul li {
        & > ul {
            @apply absolute block opacity-0 invisible transition-all;

            & li > ul {
                @apply top-0 left-full;
            }
        }
        &:hover > ul {
            @apply opacity-100 visible;
        }
    }
}
