@import "tailwindcss/base";
/* ----- Custom base styles ----- */
@import "./base.css";

@import "tailwindcss/components";
/* ----- Custom components ----- */
@import "./components.css";

@import "tailwindcss/utilities";
/* ----- Custom utilities ----- */
@import url("https://p.typekit.net/p.css?s=1&k=hdk4dfj&ht=tk&f=7986&a=3895035&app=typekit&e=css");

@font-face {
    font-family: "omnes-pro";
    src: url("https://use.typekit.net/af/fab690/000000000000000077359bed/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/fab690/000000000000000077359bed/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/fab690/000000000000000077359bed/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "stolzl";
    src: url("https://use.typekit.net/af/505ea4/00000000000000007735b006/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/505ea4/00000000000000007735b006/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/505ea4/00000000000000007735b006/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 500;
}

.font-omnes {
    font-family: "omnes-pro", sans-serif;
}

.font-stolzl {
    font-family: "stolzl", sans-serif;
}

.transitioning {
    transition: .3s ease all;
}

.max-w-xl {
    max-width: 1280px;
}

/* Site CSS */

/* Global */
html{
    scroll-behavior: smooth;
}

h1 {
    @apply relative md:text-3xl text-2xl text-primary;
}

h2 {
    @apply relative text-3xl md:text-5xl text-primary uppercase font-stolzl mb-8 font-bold;
}

a {
    @apply inline-block cursor-pointer;

    &:hover {
        @apply text-black;
    }
}

a:hover {
    @apply text-primary;
}
.btn {
    text-transform: uppercase;
}
.bouton, .btn, input[type=submit] {
    @apply relative px-8 py-4 bg-primary text-white cursor-pointer;
}

.bouton:hover, .btn:hover, input[type=submit]:hover {
    @apply bg-primary-700 text-white;
}

.bords {
    @apply border border-solid border-grey-300 p-4;
}

/* Colorbox */
.colorbox {
    box-shadow: 0px 0px 8px #333;
}

#banner img {
    @apply w-full h-full object-cover;
}
#banner::after {
    content: '';
    background: linear-gradient(
            90deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0) 100%) ;
    @apply absolute w-full h-full top-0 left-0;
}


/* Formulaire */
input[type=text], input[type=email], input[type=password], input[type=tel] {
    @apply border border-solid border-blue p-1;
}

.form {
    @apply relative block;
}

.form input[type=text] + label, .form input[type=email] + label, .form input[type=tel] + label, .form input[type=password] + label {
    position: absolute;
    left: 3%;
    top: 1.25rem;
    pointer-events: none;
    width: 100%;
    transform: translateY(-50%);
    transition: transform 200ms ease;
    color: #9a9a9a;
    text-align: left;
}

.form input[type=text]:focus + label, .form input[type=email]:focus + label, .form input[type=tel]:focus + label, .form input[type=password]:focus + label, .form textarea:focus ~ label, .form label.freeze {
    @apply text-secondary-500 font-normal text-xs !important;
    transform: translateY(-1.5rem) !important;
}

/* Cadres */


.box-ligne {
    @apply relative border-b border-solid border-grey-300;
}

/*ASIDE*/


aside {
    @apply bg-primary lg:bg-transparent fixed bottom-0 left-0 right-0 w-auto flex justify-between z-20
    md:left-auto md:right-4 md:bottom-auto md:top-40;
}

aside a {
    @apply text-white;
    font-family: "ebony", sans-serif;
}

.info-box {
    @apply w-14 h-14 bg-primary  cursor-pointer flex justify-center items-center text-white font-bold md:rounded-full md:fixed md:right-6 xl:mb-4;
    z-index:50;
}

.info-box:hover {
    @apply bg-primary-900 transitioning;
}

#aside-tel a,
#aside-devis a,
#aside-mail a{
    @apply flex items-center;
}

#aside-tel p,
#aside-devis p,
#aside-mail p{
    @apply hidden absolute w-32 opacity-0 text-white font-bold mb-0;
    opacity: 0;
}

aside.scrollingMobile{
    @apply bg-transparent;
}

aside.scrollingMobile .info-box {
    @apply w-10 h-10 -ml-10 transitioning;
}

aside.scrollingMobile .info-box.fade {
    @apply w-12 h-12 ml-auto transitioning;
}


#aside-btn{
    @apply hidden w-12 h-12 bg-primary-900 cursor-pointer flex justify-center items-center text-white font-bold;
}
aside.scrollingMobile #aside-btn {
    @apply flex;
    z-index:75;
}



@screen md{

    aside.scrollingMobile{
        @apply bg-transparent;
    }

    aside.scrollingMobile .info-box {
        @apply w-14 h-14 bg-primary cursor-pointer flex justify-center items-center text-white font-bold md:rounded-full md:fixed md:right-6 xl:mb-4;
        z-index:50;
    }

    aside.scrollingMobile .info-box.fade {
        @apply w-14 h-14 bg-primary  cursor-pointer flex justify-center items-center text-white font-bold md:rounded-full md:fixed md:right-6 xl:mb-4;
        z-index:50;
    }

    #aside-btn{
        @apply hidden;
    }
    aside.scrollingMobile #aside-btn {
        @apply hidden;
    }


    #aside-tel:hover,
    #aside-devis:hover,
    #aside-mail:hover {
        @apply transitioning w-56 justify-end;
    }

    #aside-tel:hover p,
    #aside-devis:hover p,
    #aside-mail:hover p{
        @apply block transitioning justify-start w-full h-auto opacity-100 z-30;
        position: absolute;
        width: 10rem;
        right: 3rem;
        transition-delay: 0.4s;
    }

    #aside-tel:hover svg,
    #aside-mail:hover svg,
    #aside-devis:hover svg{
        @apply mr-2;
    }

    #aside-devis {
        top: 22rem;

    }

    #aside-tel {
        top: 26rem;
    }

    #aside-mail {
        top: 30rem
    }
}
@screen lg {
    #aside-devis {
        top: 13rem;

    }

    #aside-tel {
        top: 17.5rem;
    }

    #aside-mail {
        top: 22rem
    }
}


/* Header */
header {
    @apply relative text-center lg:bg-white z-40 flex flex-col lg:flex-row items-center;
    background: rgba(255,255,255,0.85);
    backdrop-filter: blur(15px);
}
@screen lg{
    header {
        background: rgba(255,255,255,1);
        backdrop-filter: none;
    }
}
header a.logo {
    @apply bg-black;
}

header ul, header ul li {
    @apply relative list-none m-0 p-0;
}

header a {
    @apply text-black inline-block text-xl;
}

header a:hover {
    @apply text-primary transitioning;
}

header nav .active {
    color: #bd57a5;
    font-weight: 700;
}

header img {
}

header ul.subnav {
    @apply bg-white shadow-lg;
}

header ul.subnav li a {
    @apply block text-black;
}

header ul.subnav li a:hover {
    @apply text-primary-900;
}

header.scrollingdown {
    @apply shadow-lg transitioning;
}

header.scrollingdown nav {
    @apply py-0 transitioning;
}

header.scrollingdown .logo img {
    @apply h-8 lg:h-12 lg:mx-auto transitioning;
}

/* Contenu */
#main {
    @apply relative;
}

#content {
    @apply px-4 py-8;

    & ul {
        @apply list-disc list-inside mb-4;
    }
}

#hero h1 {
    @apply leading-10 text-center sm:text-left text-white sm:text-5xl text-3xl lg:w-2/3 xl:w-1/3 sm:w-2/3
    xl:ml-64 lg:ml-44 sm:ml-32;
    z-index: 12;
}

#hero .slider-overlay {
    @apply absolute w-full h-full top-0 left-0 bottom-0 right-0
    flex justify-start items-center;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.85) 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 11;
}

#slider-hero {
    @apply xl:h-176
}

#slider-hero .swiper-slide img {
    @apply h-full w-full object-cover;
}

.swiper-hero_next:after, #slider-hero .swiper-hero_prev:after {
    content: '';
    @apply absolute abs-center h-8 w-8;
}

#slider-hero .swiper-hero_prev:after {
    background: url("/img/icones/chevron-right.svg") no-repeat center;
    background-size: contain;
}

#slider-hero .swiper-hero_next:after {
    background: url("/img/icones/chevron-left.svg") no-repeat center;
    background-size: contain;
}

.h-screen {
    /*@apply h-auto relative lg:h-128;*/
    @apply h-auto relative;
}

@screen xl {
    .h-screen {
        height: 100vh;
    }

    .slider-abs-container {
        @apply absolute top-auto;
        bottom: 10%;
        width: 85%;
    }
}

.text-content p {
    @apply text-xl xl:w-2/3;
}

.swiperScroll {
    @apply h-100 xl:h-128 xl:pb-24 z-20;

    & .swiper-slide > img {
        @apply h-full w-full object-cover;
    }

    & .swiper-scroll-pagination {
        @apply absolute flex justify-between items-center bottom-5p w-7/12 x-center;
    }

    & .swiper-scrollbar {
        width: 50%;
        background-color: rgba(169, 48, 142, 0.18);
    }

    & .swiper-scrollbar-drag {
        @apply bg-primary;
    }

    & .swiper-button-next {
        bottom: 10px;
        top: auto;

        &:after {
            content: '';
            @apply absolute h-6 w-6;
            background: url("/img/icones/right-primary.svg") no-repeat center;
            background-size: contain;
        }
    }

    & .swiper-button-prev {
        bottom: 10px;
        top: auto;

        &:after {
            content: '';
            @apply absolute h-6 w-6;
            background: url("/img/icones/left-primary.svg") no-repeat center;
            background-size: contain;
        }
    }
}

.swiper-container>.swiper-scrollbar {
    @apply bottom-7 x-center;
    left: 50%;
}

#cuisine .swiperScroll .swiper-button-next,
#sdb .swiperScroll .swiper-button-next,
#dressing .swiperScroll .swiper-button-next{
    right: 8%;
    left: auto;
}

#cuisine .swiperScroll .swiper-button-prev,
#sdb .swiperScroll .swiper-button-prev,
#dressing .swiperScroll .swiper-button-prev {
    left: 8%;
    right: auto;
}

#cuisine .bg-theme {
    background: linear-gradient(rgba(25, 25, 25, 0.85), rgba(25, 25, 25, 0.85)),
    url('/img/photos/cuisine/styl_crea_cuisine_herrlisheim_Alsace_1927.jpg') no-repeat;
    background-size: cover;
}

#dressing .bg-theme {
    background: linear-gradient(rgba(25, 25, 25, 0.85), rgba(25, 25, 25, 0.85)),
    url('/img/photos/dressing/bureau_mobilo.jpg') no-repeat;
    background-size: cover;
}

#sdb .bg-theme {
    background: linear-gradient(rgba(25, 25, 25, 0.85), rgba(25, 25, 25, 0.85)) ,
    url('/img/photos/bains/Ambiance_Bain_City_23.jpg') no-repeat;
    background-size: cover;
}

.eye-container {
    cursor: pointer;
    z-index:5;
}

#cuisine .eye-container, #sdb .eye-container {
    left: -10%;
    right: auto;
    top: 10%;

}

#dressing .eye-container {
    right: -10%;
    left: auto;
    top: 10%;

}

#decouvrir .eye-container {
    right: -0.5rem;
    top: -2rem;
}

.white-circle {
    @apply relative;

    &:before {
        content: '';
        @apply absolute y-center z-50;
        left: -15px;
        width: 40px;
        height: 125px;
        background: url("/img/icones/decouvrir.svg") center no-repeat;
        background-size: contain;
    }
}

.white-circle-90 {
    @apply relative;
    transform: rotate(135deg);

    &:before {
        content: '';
        @apply absolute y-center z-50;
        left: -20px;
        width: 40px;
        height: 110px;
        background: url("/img/icones/decouvrir.svg") center no-repeat;
        background-size: contain;
    }
}

.white-circle-devis {
    @apply relative;

    &:before {
        content: '';
        @apply absolute z-50;
        top: -23px;
        left: 47px;
        width: 66px;
        height: 88px;
        background: url("/img/icones/texte-devis.svg") center no-repeat;
        background-size: contain;
    }
}

.white-circle-right {
    @apply relative;
    transform: rotate(180deg);

    &:before {
        content: '';
        @apply absolute y-center z-50;
        right: 90px;
        width: 40px;
        height: 125px;
        background: url("/img/icones/decouvrir.svg") center no-repeat;
        background-size: contain;
    }
}

.eye-container:hover > .white-circle,
.eye-container:hover > .white-circle-right,
.eye-container:hover > .white-circle-90 {
    transform: rotate(90deg);
    transition: 0.5s ease-in-out;
}

.eye-container:hover > .white-circle-devis {
    transform: rotate(-45deg);
    transition: 0.5s ease-in-out;
}

.eye-container:hover > .white-circle:before,
.eye-container:hover > .white-circle-right:before,
.eye-container:hover > .white-circle-90:before {
    background: url("/img/icones/decouvrir-primary.svg") center no-repeat;
    background-size: contain;
    transition: 0.5s ease-in-out;
}

.eye-container:hover > .white-circle-devis:before {
    background: url("/img/icones/texte-devis-primary.svg") center no-repeat;
    background-size: contain;
    transition: 0.5s ease-in-out;
}

#plan .img-content {
    z-index: 1;
    @apply h-100 m-auto lg:w-3/5 lg:h-140 lg:mr-auto lg:ml-0 xl:w-4/5 xl:h-auto overflow-hidden;

    & img {
        @apply w-full h-full object-cover;
    }
}

#plan .text-content {
    @apply mx-auto w-5/6 lg:absolute lg:w-3/5 xl:w-45p;
    z-index: 2;

    & p{
        @apply w-full;
    }
}

#crayon {
    @apply relative;

    & h3 {
        @apply relative text-3xl md:text-4xl text-black uppercase font-stolzl mb-8 font-bold;

        & span {
            @apply relative text-3xl md:text-4xl text-primary uppercase font-stolzl mb-8 font-bold;
        }
    }

    &:after {
        content: "";
        @apply absolute h-80 left-0 bottom-0 w-full;
        z-index: 0;
        background: linear-gradient(rgba(248, 248, 248, 0.85), rgba(248, 248, 248, 0.85));
    }
}

#decouvrir {
    @apply relative;
    background: linear-gradient(90deg, rgba(248, 248, 248, 0.90) 0%, rgba(248, 248, 248, 0.90) 75%),
    url('/img/sketch/bg-sketch.png') no-repeat right -60% bottom;
    background-size: contain;
}

#decouvrir h3 {
    @apply relative text-3xl md:text-4xl text-primary uppercase font-stolzl my-8 font-bold;
}

#decouvrir .eyes-container {
    right: -1rem;
    top: -2rem;
}

#decouvrir .items-img-container {
    @apply w-full md:max-h-88 overflow-hidden;

    & img {
        @apply w-full h-full object-cover;
    }
}

@screen lg{
    #plan {
        & .text-content {
            top: 50%;
            right: -10%;
            transform: translateY(-50%);
        }
    }
}

@screen xl {
    #crayon {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 1) 30%), url("/img/sketch/AdobeStock_303832326_Preview.svg") no-repeat -100% 100%;
        background-size: 70%;
        padding-top: 8rem;
    }

    #decouvrir {
        @apply pt-32 pb-24;
    }
}


#qui-sommes-nous .banner div,
#nous-choisir .banner div{
    top : 45%;
}

.banner h1{
    @apply text-5xl text-white;
}

#desc p{
    @apply text-xl xl:text-2xl;
}

@media (min-width: 768px) {
    .banner div {
       left: 0%;
    }
}

#desc p:first-child,
#desc h3{
    @apply xl:text-4xl sm:text-3xl text-2xl leading-snug font-stolzl;
}

#desc p:first-child,
#desc h2 {
    @apply text-primary;
}

#desc a{
    @apply mb-4 lg:mx-0 text-primary font-stolzl p-4 transitioning rounded-full;
}

#desc a:hover{
    @apply border-solid border border-primary;
}

.cuisine-cat h2{
    @apply text-4xl sm:text-5xl;
}

.cuisine-cat .text-content-cuisine p{
    @apply text-xl leading-9 text-left;
}

.cuisine-cat .btn{
    @apply text-2xl leading-9 abs-center mt-20 lg:mt-28 xl:mt-32 xxl:mt-24 h-20 w-64 flex items-center justify-center;
    transition: all 100ms linear;
}

.cuisine-cat .btn:hover{
    background-color: #92427F;
}

.cuisine-cat .slider-abs-container{
    height: max-content;
}

.swiperSlider {
    @apply h-180 xl:pb-24 z-20;

& .swiper-slide > img {
      @apply h-full object-cover w-full;
  }

& .swiper-slider-pagination {
      @apply absolute flex justify-between items-center bottom-10 w-7/12 x-center;
  }

& .swiper-slider-scrollbar {
    @apply absolute x-center bottom-12 w-1/2 lg:w-1/4 lg:mb-12;
      height: 0.25rem;
      background-color: rgba(169, 48, 142, 0.18);
  }

& .swiper-scrollbar-drag {
      @apply bg-primary;
  }

& .swiper-button-next {
    @apply absolute;
      right: 5%;
      left: auto;

&:after {
     content: '';
     @apply absolute w-6 h-6;
     background: url("/img/icones/right-primary.svg") no-repeat center;
     background-size: contain;
 }
}

& .swiper-button-prev {
      left: 5%;
      right: auto;

&:after {
     content: '';
     @apply absolute h-6 w-6;
     background: url("/img/icones/left-primary.svg") no-repeat center;
     background-size: contain;
 }
}
& .swiper-button-prev, .swiper-button-next {
    top: 93%;
  }
}

#banner-contact{
    background:linear-gradient(90deg, rgba(0, 0, 0, 0.90) 0%, rgba(0, 0, 0, 0.4) 100%) ,url("/img/photos/projet-banner.jpg") no-repeat center;
    background-size: cover;

}

#banner-contact>div:first-child{
    @apply text-white  lg:text-left;
}

#banner-contact .text-content{
    @apply lg:mr-16 ;
}

#banner-contact .text-content p{
    @apply w-full text-xl font-black tracking-wider leading-9 font-omnes;

}

#banner-contact .text-content h1{
    @apply mb-16 text-white text-5xl xxl:mr-48;
}

#banner-contact .tel-content {
    @apply flex flex-col text-xl font-bold font-stolzl xl:ml-16 xl:mt-8 xxl:ml-36;
}

#banner-contact svg{
    @apply w-6 h-6 xs:w-8 xs:h-8 mr-4 text-white fill-current;
    color: rgba(189, 87, 165, var(--tw-text-opacity));
    --tw-text-opacity: 1;
}

#banner-contact .tel-content p {
    @apply text-xl w-max text-white tracking-wider;
}

#decouvrir-cuisine h3{
    @apply mt-8 lg:mt-4 xxl:mt-0 mb-4 text-3xl font-bold uppercase text-primary font-stolzl;
}

#decouvrir-cuisine .text-content p{
    @apply text-base lg:w-full;
}

#decouvrir-cuisine .visite-img img {
    @apply xxl:w-96 xxl:h-64 lg:w-160 lg:h-96 md:w-200 object-cover;
}

#decouvrir-cuisine .devis-img img {
    @apply xxl:w-96 xxl:h-64 lg:w-160 lg:h-96 object-cover;
}

#decouvrir-cuisine .white-circle-devis {
    @apply relative;

&:before {
     content: '';
     @apply absolute z-50;
     top: -21px;
     left: 34px;
     width: 60px;
     height: 88px;
     background: url("/img/icones/texte-devis.svg") center no-repeat;
     background-size: contain;
 }
}

#decouvrir-cuisine .white-circle-90 {
    @apply relative;

    &:before {
         content: '';
         @apply absolute y-center z-50;
         left: -15px;
         width: 30px;
         height: 125px;
         background: url("/img/icones/decouvrir.svg") center no-repeat;
         background-size: contain;
     }
}

#decouvrir-cuisine .eye-container:hover > .white-circle:before,
#decouvrir-cuisine .eye-container:hover .white-circle-90:before {
    background: url("/img/icones/decouvrir-primary.svg") center no-repeat;
    background-size: contain;
    transition: 0.5s ease-in-out;
}

#decouvrir-cuisine .eye-container:hover > .white-circle-devis:before {
    background: url("/img/icones/texte-devis-primary.svg") center no-repeat;
    background-size: contain;
    transition: 0.5s ease-in-out;
}


#contact .text-content h2{
    @apply mb-8;
}

#contact .text-content p{
    @apply w-full
}

#contact h2{
    @apply mb-8 lg:mb-0 break-words;
}
.h2-break-words h2 {
    @apply break-words;
}

input[type=text], input[type=email], input[type=password], input[type=tel] {
    @apply border border-solid border-grey-300 rounded-sm p-1 w-full;
}

label {
    @apply mb-5;
}

textarea {
    @apply border-solid border border-grey-300 rounded-sm p-4 w-full xs:w-3/5 lg:w-45p;

}

#visite-virtuelle p{
    @apply text-center text-2xl font-bold;
    font-family: "stolzl", Sans-Serif;

}

#visite-virtuelle p:last-of-type {
    @apply w-full sm:w-4/5 lg:w-3/5 xl:w-4/5 my-8;
}

#visite-virtuelle p:last-of-type  iframe {
    @apply w-full;
    max-width: 100%;
}


#equipe .text-content p{
    @apply w-full
}
#notre-equipe .text-content p{
    @apply w-full;
}

/* Pourquoi nous choisir */

#nous-choisir .text-content{
    @apply lg:w-2/5
}

#nous-choisir .text-content p{
    @apply w-full;
}

/* Mentions légales */
#mention,
#cgv,
#error{
    @apply mx-auto w-3/5 py-48;

}

#mention,
#cgv #error {
    margin: 6rem auto;
    width: 75%;

    & h1{
        text-transform: uppercase;
        font-size: 3rem;
        margin-bottom: 2rem;
        line-height: 3rem;
      }

    & h2{
          font-size: 1.5rem;
          margin-top: 4rem;
     }

    & a:not(.btn){
      color :rgba(189, 87, 165, var(--tw-text-opacity));
          --tw-text-opacity:1;
    }

    & a.btn{
        @apply text-white;
    }

    & p{
          font-size: 1rem;
      }

    & ul{
      list-style: inside;
    }

    & img{
        width: 100px;
        height: 25px;
    margin-bottom: 2rem;
      }
}

/* Footer */

footer {

    & h4, h3, h2 {
        @apply text-xl uppercase font-bold text-white relative mb-8 font-stolzl;

        &:after{
            content: '';
            @apply absolute left-0 -bottom-4 w-8 h-1 bg-primary;
        }
    }




}

#footer-top{
    @apply bg-darkgrey text-base;

    & p {
        @apply text-white text-lg;

    }

    & a {
        @apply text-white hover:text-primary text-lg;
    }

    & span {
        @apply text-white;
    }
}

#footer-bottom {
    & p {
        @apply text-white text-xs;
    }
    & a {
        @apply text-white hover:text-primary text-xs;
    }
    & span {
        @apply text-white;
    }
}

/* Message alert */
.alert[role=alert] {
    position: absolute;
    z-index: 999;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
}

/* Responsive */

@screen sm {
    #hero h1{
        line-height: 3.5rem;
    }

    #cuisine .swiperScroll .swiper-button-next,
    #sdb .swiperScroll .swiper-button-next {
        right: 15%;
        left: auto;
    }

    #cuisine .swiperScroll .swiper-button-prev,
    #sdb .swiperScroll .swiper-button-prev {
        left: 15%;
        right: auto;
    }

    #dressing .swiperScroll .swiper-button-next {
        right: 15%;
        left: auto;
    }

    #dressing .swiperScroll .swiper-button-prev {
        right: 81%;
        left: auto;
    }

    #decouvrir .eye-container {
        right: -1rem;
        left: auto;
    }

    .swiperSlider .swiper-button-next {
        right: 15%;
        left: auto;
    }

    .swiperSlider .swiper-button-prev {
        left: 15%;
        right: auto;
    }
}
@screen lg {
    .swiperScroll {

    .swiper-container > .swiper-scrollbar {
        transform: none;
    }

& .swiper-slide > img {
      @apply h-full w-full object-cover;
  }

& .swiper-scroll-pagination {
      @apply absolute flex justify-between items-center transform-none;
      width: 40%;
      bottom: 30px;
  }

& .swiper-scrollbar {
      @apply h-1;
      width: 35%;
      bottom: 40px;
      background-color: rgba(169, 48, 142, 0.18);
      transform: none;
  }

& .swiper-scrollbar-drag {
      @apply bg-primary;
  }

& .swiper-button-next {
      bottom: 20px;
      top: auto;

&:after {
     content: '';
     @apply absolute h-4 w-4;
     background: url("/img/icones/right-primary.svg") no-repeat center;
     background-size: contain;
 }
}

&
.swiper-button-prev {
    bottom: 20px;
    top: auto;

&:after {
    content: '';
    @apply absolute h-4 w-4;
    background: url("/img/icones/left-primary.svg") no-repeat center;
    background-size: contain;
}

}
}

#cuisine .swiperScroll .swiper-scroll-pagination,
#sdb .swiperScroll .swiper-scroll-pagination {
    left: 18%;
    right: auto;
}

#cuisine .swiperScroll .swiper-scrollbar,
#sdb .swiperScroll .swiper-scrollbar {
    left: 20%;
    right: auto;
}

#cuisine .swiperScroll .swiper-button-next,
#sdb .swiperScroll .swiper-button-next {
    right: 39%;
    left: auto;
}

#cuisine .swiperScroll .swiper-button-prev,
#sdb .swiperScroll .swiper-button-prev {
    left: 15%;
    right: auto;
}

#dressing .swiperScroll .swiper-scroll-pagination {
    right: 18%;
    left: auto;
}

#dressing .swiperScroll .swiper-scrollbar {
    right: 20%;
    left: auto;
}

#dressing .swiperScroll .swiper-button-next {
    right: 15%;
    left: auto;
}

#dressing .swiperScroll .swiper-button-prev {
    right: 59%;
    left: auto;
}

.swiperSlider {
    height : 45rem;
&
.swiper-slider-pagination {
    width: 57%;
}

&
.swiper-slider-scrollbar {
    width: 45%;
    top: 93%;
}

&
.swiper-button-prev, .swiper-button-next {
    top: 95%;
    width: 1rem;
    height: 1rem;
}

& .swiper-button-next {
&:after {
     @apply h-4 w-4;
 }
}

& .swiper-button-prev {
&:after {
     @apply h-4 w-4;
 }
}

&
.swiper-button-next {
    right: 11%;
    left: auto;
}

&
.swiper-button-prev {
    left: 11%;
    right: auto;
}
}

.cuisine-cat .slider-abs-container {
    width: 44%;
}

.cuisine-cat:nth-of-type(2) .slider-abs-container {
    top: 68rem;
}

.cuisine-cat:nth-of-type(3) .slider-abs-container {
top: 131rem;
}

.cuisine-cat:nth-of-type(4) .slider-abs-container {
 top: 194rem;
 }

.cuisine-cat:nth-of-type(5) .slider-abs-container {
    top: 257rem;
}

}

@screen xl {
    .cuisine-cat .slider-abs-container {
        width: 59%;
    }

    .swiperSlider {
        height: 45rem;

&
    .swiper-slider-pagination {
        width: 54%;
    }
&
    .swiper-button-next {
        right: 18%;
        left: auto;
    }

&
    .swiper-button-prev {
        left: 18%;
        right: auto;
    }
}
}

@screen xxl {
    .swiperSlider {
        height: 39rem;

&
    .swiper-slider-pagination {
        width: 51%;
        top: 36rem;
    }
&
    .swiper-button-next {
        right: 20%;
        left: auto;
    }

&
    .swiper-button-prev {
        left: 20%;
        right: auto;
    }
}
    .cuisine-cat .slider-abs-container {
        width: 56%;
    }

    .cuisine-cat:nth-of-type(2) .slider-abs-container {
        top: 69rem;
    }

    .cuisine-cat:nth-of-type(5) .slider-abs-container {
        top: 243rem;
    }
    .cuisine-cat:nth-of-type(4) .slider-abs-container {
        top: 185rem;
    }
    .cuisine-cat:nth-of-type(3) .slider-abs-container {
        top: 127rem;
    }
}

.home-coup-crayon h3 {
    @apply break-words;
}

.home-coup-crayon a {
    @apply py-4 px-8 cursor-pointer transition-all font-bold;
    letter-spacing: 3px;
    @apply bg-primary-500 hover:bg-primary-700 text-white hover:text-white text-lg uppercase text-center relative x-center lg:-bottom-16 md:-bottom-14 -bottom-3 ;

}


